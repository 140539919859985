<template>
  <div class="customs-post">
    <svg-icon name="customs-post"></svg-icon>
    <span class="customs-post__info">
      {{ $t('master.destination') }}: {{ customsPost }}
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      customsPost: 'Ягодин',
    };
  },
};
</script>

<style lang="sass" scoped>
.customs-post
  display: flex
  align-items: center
  justify-content: center
  width: 420px
  border-radius: var(--primary-border-radius)
  border-bottom: 1px solid var(--primary-color-light-grey-3)
  padding: 0 0 20px
  margin-bottom: 20px

.customs-post__info
  color: var(--btn-color-blue)
  font-weight: 600
  font-size: 18px
  line-height: 22px
  margin-left: 16px

.icon-customs-post
  width: 36px
  height: 18px

.customs-post__info-point
  color: var(--primary-color-dark-blue)
</style>
