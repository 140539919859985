<template>
  <div class="auth-form">
    <div class="auth-form__customs-post">
      <CustomsPost />
    </div>
    <Heading level="1" class="">
      {{ $t('form.authorisation') }}
    </Heading>
    <form class="auth-form__form form" @submit.prevent>
      <div class="form__input">
        <LabelField id="login" :label-value="$t('form.number')" />
        <TextField
          id="user-id"
          v-model.trim="fields.login.value"
          type="text"
          name="login"
          :placeholder="$t('form.enter_number')"
          required
          :title="$t('form.number_rule')"
          :error-txt="getLoginError"
          :error="fields.login.isValid || !!serverError"
          error-border-visible
          :is-show-icon="!Boolean(serverError)"
          is-show-info-message
          @isFocus="clearField"
        />
      </div>

      <div class="form__input">
        <LabelField id="password" :label-value="$t('form.password')" />
        <TextField
          id="password"
          v-model.trim="fields.password.value"
          type="password"
          name="password"
          :placeholder="$t('form.enter_password')"
          required
          :title="$t('form.password_rule')"
          :error-txt="getpasswordError"
          :error="fields.password.isValid || !!serverError"
          error-border-visible
          :is-show-icon="!Boolean(serverError)"
          is-show-info-message
          @isFocus="clearField"
        />
        <div class="form__input-error">
          <svg-icon
            v-if="getServerError"
            name="error-icon"
            class="error-icon"
          ></svg-icon>
          <span>
            {{ getServerError }}
          </span>
        </div>
      </div>

      <div class="form__btn">
        <ButtonBase color="blue" size="max" @click.native="authHandler">
          <template v-if="!loadingSigninBtn">
            {{ $t('form.sign_in') }}
          </template>
          <Loader v-else color="white" size="sm" />
        </ButtonBase>
      </div>
    </form>
  </div>
</template>

<script>
import TextField from '@/elements/Inputs/TextField.vue';
import LabelField from '@/elements/Inputs/LabelField.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import Heading from '@/elements/Heading/Heading.vue';
import Loader from '@/elements/Loader/Loader.vue';

import CustomsPost from '@/components/CustomsPost/CustomsPost.vue';

import {
  required,
  minLength,
  maxLength,
  numeric,
} from 'vuelidate/lib/validators';

export default {
  components: {
    TextField,
    LabelField,
    ButtonBase,
    Heading,
    CustomsPost,
    Loader,
  },
  data() {
    return {
      serverError: null,
      fields: {
        login: {
          value: '111111',
          isValid: false,
        },
        password: {
          value: '123123qwe',
          isValid: false,
        },
      },
      loadingSigninBtn: false,
    };
  },
  computed: {
    getServerError() {
      return this.serverError && this.isFieldHasValue
        ? this.$t('form.wrong_credentials')
        : '';
    },
    getLoginError() {
      return !Boolean(this.serverError) ? this.$t('form.number_error') : '';
    },
    getpasswordError() {
      return !Boolean(this.serverError) ? this.$t('form.password_error') : '';
    },
    isFieldHasValue() {
      return (
        !!this.fields.login.value.length && !!this.fields.password.value.length
      );
    },
  },

  methods: {
    clearField(name) {
      this.fields[name].isValid = false;
      this.serverError = null;
    },
    async authHandler() {
      this.fields.login.isValid = this.$v.fields.login.value.$invalid;
      this.fields.password.isValid = this.$v.fields.password.value.$invalid;

      let data = {
        number: this.fields.login.value,
        password: this.fields.password.value,
      };

      if (
        !this.$v.fields.login.value.$invalid &&
        !this.$v.fields.password.value.$invalid
      ) {
        this.loadingSigninBtn = true;
        this.serverError = await this.$store.dispatch('authorization', data);
        this.loadingSigninBtn = false;
      }
    },
  },

  validations: {
    fields: {
      login: {
        value: {
          required,
          numeric,
          minLength: minLength(6),
          maxLength: maxLength(6),
        },
      },
      password: {
        value: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(15),
        },
      },
    },
  },
};
</script>

<style lang="sass">
.auth-form
  background-color: var(--primary-color-white)
  box-shadow: var(--primary-border-shadow-1)
  border-radius: var(--primary-border-radius)
  border: 1px solid var(--primary-color-light-grey-3)
  padding: 40px 0 80px 0
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column

  @media screen and (max-width: 1199px)
    padding: 40px 0

.form
  min-width: 420px

.form__input
  display: flex
  flex-direction: column
  align-items: flex-start
  margin-bottom: 48px
  position: relative

  @media screen and (max-width: 1199px)
    margin-bottom: 44px

  .input
    border: 1px solid rgba(40, 59, 89, 0.3)

.form__input-error
  display: flex
  align-items: center
  font-size: 14px
  color: var(--primary-color-error)
  position: absolute
  bottom: -20px
  left: 0

.error-icon
  width: 14px
  height: 14px
  margin-right: 4px
</style>
