<template>
  <div class="auth">
    <div class="auth__inner">
      <div class="auth__form-box">
        <AuthForm />
      </div>
    </div>
  </div>
</template>

<script>
import AuthForm from '@/components/Auth/AuthForm.vue';

export default {
  components: {
    AuthForm,
  },
  created() {
    this.$store.commit('LOGOUT_USER');
  },
};
</script>

<style lang="sass" scoped>
.auth__inner
  max-width: 650px
  margin: 0 auto
  padding: 32px 0 40px 0
  text-align: center

.auth__location
  margin-bottom: 32px

  @media screen and (max-width: 1199px)
    margin-bottom: 40px
</style>
